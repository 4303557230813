import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Event = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="イベント"
      pagedesc="イベントは１日単位で利用できるイベントスペースです。展示やサンプリングなど様々な商品を、実際に見て触ってもらえる、唯一その場で反応が見れる広告商品です。
      新商品の展示のみの展開、車両展示、新商品のサンプリング、物販も併せた体験型イベントなど様々な展開が可能です。"
      pagepath={location.pathname}
      pageimg={data.eventimage.childImageSharp.original.src}
      pageimgw={data.eventimage.childImageSharp.original.width}
      pageimgh={data.eventimage.childImageSharp.original.height}
    />
    <section className="area">
    <div className="container">
      <h2 className="bar">イベント</h2>
      <br />
      <figure>
        <GatsbyImage image={data.eventimage.childImageSharp.gatsbyImageData} alt="" />
      </figure>
      <br />
      <p className="article">
      <span className="article-sub">イベントについて</span><br />
      ・１日単位で利用できるイベントスペースです。装飾した造作物やモニターなど置いて、実際の商品を展示して触って貰ったり操作性を体験して貰ったりで
その場で利用者の反応を見れるのが特徴です。これから発売されるドリンクなどのサンプリングなども出来ますので、購買につなげやすいです。<br />
また、新商品の展示のみの展開、車両展示、物販も併せた販売型イベントや新しい化粧品等の体験イベントなどの展開も可能です。<br /><br />
行楽シーズンに向けて、地方の特産品や観光見所告知PRして観光誘致の刺激をする内容などもおすすめです。<br />
あとは、特定のジャンルでより深く知識や情報を深めてもらう様な啓蒙活動的なイベントも可能です。<br /><br />
イベントは、駅構内という立地柄かなりの駅利用者がいますので、目にする確率も高く興味を引きますので、１日での訴求力はかなり強いです。
２ヶ月以上前からイベント詳細の確認を経て本番実施という流れで進めていきます。設営撤去の作業は終電から始発までの間に行います。
駅により若干の前後はありますが、基本のイベント実施時間は１０ ：００〜２１：００前後となります。
内容の事前審査確認も出来ますので、ご相談ください。
      </p>
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/event_cat/`}>
        <button className="button-first">イベント一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    eventimage: file(relativePath: {eq: "eventimage.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default Event